import { MEASURE_TITLES } from '~/constants/analytics'

export const MEASURE_FORMULAS = {
  [MEASURE_TITLES.EMPLOYEE_GROWTH_RATE]: '\\dfrac{E - B}{B} \\times 100',

  [MEASURE_TITLES.EMPLOYEE_ATTRITION]:
    '\\dfrac{L \\times 2}{B + E} \\times 100',
  [MEASURE_TITLES.EMPLOYEE_ATTRITION_REGRETTED]:
    '\\dfrac{L \\times 2}{B + E} \\times 100',
  [MEASURE_TITLES.EMPLOYEE_ATTRITION_NOT_REGRETTED]:
    '\\dfrac{L \\times 2}{B + E} \\times 100',
  [MEASURE_TITLES.EMPLOYEE_TURNOVER]: '\\dfrac{L + A}{B \\times 2} \\times 100',
  [MEASURE_TITLES.EMPLOYEE_RATIO_DEPARTURE_REGRETTED]:
    '\\boxed{\\frac U D \\times 100}',
  [MEASURE_TITLES.EMPLOYEE_RETENTION]: '\\dfrac{B - L}{B} \\times 100',
  [MEASURE_TITLES.EMPLOYEE_TRIAL_PERIOD_NON_TRANSFORMATION_RATE]:
    '\\dfrac{L}{L + T} \\times 100',
  [MEASURE_TITLES.EMPLOYEE_FIRST_YEAR_NON_TRANSFORMATION_RATE]:
    '\\dfrac{L}{L + F} \\times 100',
  [MEASURE_TITLES.EMPLOYEE_FIRST_SIX_MONTHS_NON_TRANSFORMATION_RATE]:
    '\\dfrac{L}{L + F} \\times 100',
  [MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_RATE]: '\\boxed{\\frac U T \\times 100}',
  [MEASURE_TITLES.EMPLOYEE_RATIO_UNPLANNED_SHORT_ABSENCE]:
    '\\boxed{\\frac S T \\times 100}',
  [MEASURE_TITLES.EMPLOYEE_RATIO_EMPLOYEE_ABSENT]:
    '\\boxed{\\frac A E \\times 100}',
  [MEASURE_TITLES.EMPLOYEE_ABSENCE_FREQUENCY]: '\\boxed{\\frac U E}',
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_ABSENCE_UNPLANNED_DURATION]:
    '\\boxed{\\frac U T}',
  [MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_COST_RATE]:
    '\\boxed{\\frac S T \\times 100}',

  [MEASURE_TITLES.RECRUITMENT_GLOBAL_ACCEPTANCE_RATE]:
    '\\boxed{\\frac R A \\times 100}',
  [MEASURE_TITLES.RECRUITMENT_OFFER_ACCEPTANCE_RATE]:
    '\\boxed{\\frac C M \\times 100}',
  [MEASURE_TITLES.RECRUITMENT_GLOBAL_REJECTION_RATE]:
    '\\boxed{\\frac D A \\times 100}',
  [MEASURE_TITLES.RECRUITMENT_WITHDRAWAL_RATE]:
    '\\boxed{\\frac W A \\times 100}',
  [MEASURE_TITLES.RECRUITMENT_OFFER_REJECTION_RATE]:
    '\\boxed{\\frac R M \\times 100}'
}
